/*************基本設定*************/
html{
	font-size: 14px;
	color: #4e4e4e;
}
body{
	margin:0px;
	padding:0px;
	line-height: 1.5em;
	font-family: 'Arial',YuGothic,'Yu Gothic','Hiragino Kaku Gothic ProN','ヒラギノ角ゴ ProN W3','メイリオ', Meiryo,'ＭＳ ゴシック',sans-serif;
	/* display: flex;*/
	/*flex-flow: column;*/
	/*min-height: 100vh; */
	/*position: relative;*/
	/*padding-bottom: 120px;*/
    /*box-sizing: border-box;*/
}
#wrapper{
      display: flex;
      flex-direction: column;
      min-height: 100vh;  
}


input { vertical-align: middle; }

/* ヘッダー */
.header{
    width: 90%;
    border-bottom: solid 1px #707070;
    margin: 0 auto;
}
.header img{
    padding: 0 0 0 20px;
    width: 175px;
}
@media screen and (max-width:600px) {
    .header{
        text-align: center;
    }
	.header img{
        padding: 3px;
    }
}

/* フッター*/
.footer{
    margin-top: auto;
    width: 100%;
    background: #F5F5F5;
    font-size: 10px;
    text-align: center;
   
}

.footer img{
    width: 120px;
    vertical-align: middle;
}
.footer p{
    display: inline-block;
    vertical-align: middle;
    margin: 10px 20px;
}

/* 色々 */
.checkboxA{
    font-size: 12px;
}

.box{
	margin:15px auto 50px auto;
	max-width:500px;
	box-shadow: 0 10px 25px 0 rgba(0, 0, 0, .5);
	padding:20px 30px;
}
@media screen and (max-width:600px) {
   .box{
        margin:15px auto;
    	max-width:500px;
        box-shadow: none;
    	padding:20px 30px;
    }
}

.small_font{
	font-size:12px;
}


/*ログインページ*/
.login_area{
	width:100%;
	padding:20px 0;
	text-align:center;
	margin-bottom:30px;
}
.form_area{
	margin:10px 0;
	padding-bottom:20px;
}
.forget_pass{
	font-size:12px;
	float:right;
}
.forget_pass:link { 
	color: #1592E6;
	text-decoration:none;
}
.forget_pass:visited { color: #1592E6; }
.forget_pass:hover {
	color: #ef8d31;
	text-decoration:underline;
}
.forget_pass:active { color: #1592E6; }

.regist_area{
	width:100%;
	padding:20px 0;
	text-align:center;
}





/* 利用規約 */
.note{
    color: #FF0000;
}
.tos_area section{
    margin: 20px;
    width: 90%;
    margin: 30px auto;
}
.tos_area section h1{
    font-size: 23px;
}
.tos_area section h2{
    font-size: 18px;
}
.tos_area section h3{
    font-size: 16px;
}
.tos_text{
    margin: 0 auto 50px auto;
    width: 90%;
}
.tos_contents{
    max-height: 50vh;
    overflow-y: auto;
    border: 1px solid #e5e5e5;
    padding: 5px 10px;
}
.tos_area .center{
    text-align: center;
}

@media screen and (max-width:600px) {
   .tos_text{
        margin: 0 auto;
        width: 100%;
    }
}

/*仮登録*/
.radio_area{
    display: -webkit-flex;
    display: flex;
    /*justify-content: center;*/
    align-items: baseline;
    width: 90%;
    margin: auto;
    border-bottom: 1px solid #afafaf;
}
.radio_title{
    
}
.radio_contents{
    margin-left: 20px;
    
}


/*メール登録完了*/
.note{
    color: #FF0000;
}
.mailcomp_area section{
    margin: 20px;
    width: 90%;
    margin: 10px auto;
}
.success_icon{
    text-align:center;
}
.mailcomp_area section h2{
    font-size: 18px;
    margin:10px 0 0 0;
}
.tos_text{
    margin: 0 auto 50px auto;
    width: 90%;
}
.sub_text{
    text-align:center;
    font-size:20px;
    margin-top:0;
    margin-bottom:10px;
}
.sentence{
    margin:5px 0 20px 0;
}
@media screen and (max-width:600px) {
   .tos_text{
        margin: 0 auto;
        width: 100%;
    }
}

